<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { nanoid } from 'nanoid';
export default {
  name: '',
  data() {
    return {};
  },
  mounted() {
    this.fishkefu.init();
    let taskId = localStorage.getItem('taskId');
    if (!taskId) {
      taskId = nanoid();
      localStorage.setItem('taskId', taskId);
    }
    console.log('唯一id', taskId);
    this.reportEvent(taskId)
  },

  methods: {
    //埋点数据上报
    async reportEvent(c_no) {
      let res = await this.$axios.post('https://middlegrounpcomplaint.juzan.info/api/event/index', {
        event:'page_view',
        attr:'main',
        c_no:c_no,
        source:1,
      });
    },
  },
};
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
  list-style: none;
}
img {
  width: 100%;
  height: 100%;
  display: block;
}
div {
  box-sizing: border-box;
}

.van-toast {
  padding: 0 32px !important;
  font-size: 28px !important;
  line-height: 60px !important;
}
.van-image {
  width: 150px !important;
  height: 150px !important;
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: '在线客服中心'
    },
    component: HomeView
  },
  {
    path: '/service',
    redirect: '/' // 简单重定向
  },
  {
    path: '/online',
    name: 'online',
    meta: {
      title: '在线退款'
    },
    component: () => import('../views/online/Online.vue')
  },
  {
    path: '/oldcomplaint',
    name: 'oldcomplaint',
    meta: {
      title: '提交投诉'
    },
    component: () => import('../views/oldComplaint/OldComplaint.vue')
  },
  {
    path: '/question',
    name: 'question',
    meta: {
      title: '更多帮助'
    },
    component: () => import('../views/question/Question.vue')
  },
  {
    path: '/succ',
    name: 'succ',
    meta: {
      title: '交易投诉'
    },
    component: () => import('../views/success/Success.vue')
  },
  // {
  //   path: '/chat',
  //   name: 'chat',
  //   meta: {
  //     title: '在线办理退款'
  //   }, 
  //   component: () => import('../views/chat/Chat.vue')
  // },
  // {
  //   path: '/question1',
  //   name: 'question1',
  //   meta: {
  //     title: '猜你想问'
  //   }, 
  //   component: () => import('../views/question/Question1.vue')
  // },
  // {
  //   path: '/question2',
  //   name: 'question2',
  //   meta: {
  //     title: '猜你想问'
  //   }, 
  //   component: () => import('../views/question/Question2.vue')
  // },
  // {
  //   path: '/question3',
  //   name: 'question3',
  //   meta: {
  //     title: '猜你想问'
  //   }, 
  //   component: () => import('../views/question/Question3.vue')
  // },
  // {
  //   path: '/question4',
  //   name: 'question4',
  //   meta: {
  //     title: '猜你想问'
  //   }, 
  //   component: () => import('../views/question/Question4.vue')
  // },
  // {
  //   path: '/question5',
  //   name: 'question5',
  //   meta: {
  //     title: '猜你想问'
  //   }, 
  //   component: () => import('../views/question/Question5.vue')
  // },
  // {
  //   path: '/searchq',
  //   name: 'searchq',
  //   meta: {
  //     title: '我的客服'
  //   }, 
  //   component: () => import('../views/search/Searchq.vue')
  // },

  // {
  //   path: '/complaint',
  //   name: 'complaint',
  //   meta: {
  //     title: '交易投诉'
  //   }, 
  //   component: () => import('../views/complaint/Complaint.vue')
  // },


  // {
  //   path: '/informantcenter',
  //   name: 'informantcenter',
  //   meta: {
  //     title: '举报中心'
  //   }, 
  //   component: () => import('../views/informantCenter/InformantCenter.vue')
  // },
  // {
  //   path: '/fraud',
  //   name: 'fraud',
  //   meta: {
  //     title: '举报中心'
  //   }, 
  //   component: () => import('../views/fraud/Fraud.vue')
  // },
  // {
  //   path: '/notoneself',
  //   name: 'notoneself',
  //   meta: {
  //     title: '举报中心'
  //   }, 
  //   component: () => import('../views/notOneself/NotOneself.vue')
  // },
  // {
  //   path: '/notoneselfform',
  //   name: 'notoneselfform',
  //   meta: {
  //     title: '举报中心'
  //   }, 
  //   component: () => import('../views/notOneself/Form.vue')
  // },
  // {
  //   path: '/nothappy',
  //   name: 'nothappy',
  //   meta: {
  //     title: '举报中心'
  //   }, 
  //   component: () => import('../views/notHappy/NotHappy.vue')
  // },
  // {
  //   path: '/nothform',
  //   name: 'nothform',
  //   meta: {
  //     title: '举报中心'
  //   }, 
  //   component: () => import('../views/notHappy/NothForm.vue')
  // },
]

//以下代码解决路由地址重复点击的报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
